import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import '@aws-amplify/ui-react/styles.css'

import { Amplify } from 'aws-amplify';
import config from './aws-exports';
import { AmplifyProvider } from '@aws-amplify/ui-react';

Amplify.configure(config);

const theme= {
  name: 'labCoat',
  tokens: {
    colors: {
      background: {
        primary: { value: 'dark blue'}
      }
    }
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AmplifyProvider theme={theme}>
    <App />
  </AmplifyProvider>
);